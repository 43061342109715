/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
const getImageDimensions = (url: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    if (typeof window === "undefined") {
      return reject("Window is not defined");
    }
    if (!url) {
      return reject("Url is not defined!");
    }
    const timer: number | null = null;
    const img = new Image();
    img.addEventListener("load", () => {
      if (timer) {
        clearTimeout(timer);
      }
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    });
    img.addEventListener("error", (event) => {
      if (timer) {
        clearTimeout(timer);
      }
      reject(`${event.type}: ${event.message}`);
    });
    img.src = url;
  });
};

const validPxSizeImage = async (
  url: string,
  forcesImageSize: {
    minWidth: number;
    minHeight: number;
    maxWidth: number;
    maxHeight: number;
    ratio?: number;
  }
) => {
  if (forcesImageSize) {
    const dimensions = await getImageDimensions(url);
    return (
      dimensions.width >= forcesImageSize.minWidth &&
      dimensions.height >= forcesImageSize.minHeight &&
      dimensions.width <= forcesImageSize.maxWidth &&
      dimensions.height <= forcesImageSize.maxHeight
    );
  }
  return true;
};

export { validPxSizeImage };
